import {
  Content,
  DropdownMenuContentProps as RadixContentProps,
  DropdownMenuSubContentProps as RadixSubContentProps,
  SubContent,
} from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { paletteV2 } from '../../../theme/palette';

interface MenuContentProps extends RadixContentProps {
  children: React.ReactNode;
}

interface MenuSubContentProps extends RadixSubContentProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: paletteV2.grey[900],
    padding: theme.spacing(1),
    boxShadow: theme.shadows[8],
    borderRadius: theme.spacing(0.5),
    zIndex: theme.zIndex.tooltip,
    '& div[role="menuitem"]': {
      color: theme.palette.common.white,
      borderRadius: theme.spacing(0.5),
      // If is a sub trigger w menu, adjust space for chevron icon
      '&[aria-haspopup="menu"]': {
        '& > *': {
          padding: theme.spacing(1, 0, 1, 1),
        },
      },
      '& > *': {
        minWidth: theme.spacing(16),
        padding: theme.spacing(1),
      },
      '&:not([data-disabled=""])': {
        backgroundColor: paletteV2.grey[900],
        '&[data-hovered="true"]': {
          backgroundColor: paletteV2.grey[700],
          outline: `2px solid ${paletteV2.lime[100]}`,
          cursor: 'pointer',
        },
        '&[data-hovered="false"]': {
          outline: 'none',
        },
      },
      '&[data-disabled=""]': {
        color: paletteV2.grey[400],
      },
    },
  },
}));

export const MenuSubContent = React.forwardRef<
  HTMLDivElement,
  MenuSubContentProps
>((props, forwardRef) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  return (
    <SubContent
      ref={forwardRef}
      className={clsx(classes.content, className)}
      {...rest}
    >
      {children}
    </SubContent>
  );
});

const MenuContent = React.forwardRef<HTMLDivElement, MenuContentProps>(
  (props, forwardRef) => {
    const { children, className, ...rest } = props;
    const classes = useStyles();

    return (
      <Content
        ref={forwardRef}
        className={clsx(className, classes.content)}
        align="start"
        {...rest}
      >
        {children}
      </Content>
    );
  }
);

export default MenuContent;
